body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow-y: scroll; /* Always show vertical scrollbar */
}

.heading {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
}

.heading h1 {
  text-decoration: underline;
  margin-bottom: 5px;
}

.sub-heading {
  font-size: 18px;
  font-style: italic;
}

.tabs-container {
  padding-top: 0px;
}

.tabs {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #f0f0f0;
}

.tab {
  flex: 1;
}

.tab a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  text-align: center;
  transition: background-color 0.3s ease;
}

.tab a:hover {
  background-color: #ddd;
}

.tab-content {
  padding: 20px;
  margin-left: 275px;
  margin-right: 200px;
}
/* This is the sidebar itself */
.sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  padding: 10px;
}
/* These are the sidebar buttons themselves */
.sidebar button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 200px;
}

.sidebar button:hover {
  background-color: #ddd;
}
/* This is the content that is displayed once a sidebar is clicked */
.section {
  margin-left: 250px;
  margin-top: -200px; 
  margin-right: 200px;

}

.image-size {
  width: 300px;
  height: 400px;
}

/* This is the content that is displayed once the tab Research or Paul's Bookcase is clicked */
.section2 {
  margin-left: 250px;
  margin-top: -130px; 
  margin-right: 200px;

}
/* This is the content that is displayed once a sidebar is clicked that contains an image */
.section-image {
  position: fixed;
  top: 380px;
  left: 900px;
  text-align: center;
  margin-right: 200px;
}
/* Everything below with "custom-table" is for the table under "Paul's bookcase"*/
.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

.custom-table th {
  background-color: #f2f2f2;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table tbody tr:hover {
  background-color: #f2f2f2;
}

.playground {
  text-align: center;
  margin-top: 0px;
}

.learnBlockchain {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  text-align: center;
  transition: background-color 0.3s ease;
  margin: 0 auto; /* Center horizontally */
}

/* Base block style */
.block {
  border: 2px solid #333;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  width: fit-content; 
  margin: 0 auto;
  margin-bottom: 20px;
}

/* Style for block header */
.block-header {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Style for block content */
.block-content {
  font-size: 16px;
  color: #555;
}

/* Style for block footer */
.block-footer {
  font-size: 14px;
  color: #777;
  margin-top: 10px;
}

/* Style for nonce */
.nonce {
  font-family: monospace;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 3px;
}

/* Style for hash value */
.hash-value {
  font-family: monospace;
  color: #009900;
}

/* Style for proof of work success */
.proof-success {
  color: #009900;
  font-weight: bold;
}

/* Style for proof of work failure */
.proof-failure {
  color: #ff0000;
  font-weight: bold;
}




/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .heading {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 5px;
    font-size: 10px;
  }

  .heading h1 {
    text-decoration: underline;
    margin-bottom: 5px;
  }
  
  .sub-heading {
    font-size: 12px;
    font-style: italic;
    margin-top: 5px;

  }
  
  .section {
    margin-left: 120px;
    margin-right: 0px;
    font-size: 10px;

  }

  .section h3 {
    margin-left: 0px;
    margin-top: -275px; 
    margin-right: 0px;
    font-size: 12px;
  }

  .section img {
    margin-left: 0px;

  }

  /* This is the content that is displayed once the tab Research or Paul's Bookcase is clicked */
  .section2 {
    margin-left: 125px;
    margin-top: -170px; 
    margin-right: 0px;
    font-size: 10px;
  }
  /* This is the content that is displayed once a sidebar is clicked that contains an image */
  .section-image {
    position: fixed;
    top: 270px;
    left: 130px;
    text-align: center;
    margin-right: 20px;
  }

  .section-image p1{
    position: fixed;
    top: 170px;
    left: 210px;
    text-align: center;
    margin-right: 20px;
  }

  .tab-content {
    padding: 10px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 12px;
  }

  .tab-content h2 {
    margin-left: 35px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 16px;

  }

  .image-size {
    width: 75px;
    height: 100px;
  }

  /* This is the sidebar itself */
  .sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    padding: 10px;
  }
  /* These are the sidebar buttons themselves */
  .sidebar button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100px;
  }

  .tabs {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: #f0f0f0;
  }
  
  .tab {
    flex: 1;
    font-size: 12px;

  }

  .custom-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 10px;
    margin-left: 0px;
    margin-right: 35px;
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid #dddddd;
    padding: 1px;
    text-align: center;
  }
  
  .custom-table th {
    background-color: #f2f2f2;
  }
  
  .custom-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .custom-table tbody tr:hover {
    background-color: #f2f2f2;
  }

}

@media screen and (max-width: 576px) {
  /* Adjust styles for even smaller screens */
}